<template>
  <dashboard-layout>
    <div class="w-10/12 mx-auto">
      <div class="p-4 flex-col">
        <h1 class="text-green-400 font-bold text-4xl w-full text-center">
          Buscar <span class="text-green-500">Talento</span>
        </h1>
        <form
          class="w-5/12 mx-auto"
          @submit.prevent="submit"
          autocomplete="off"
        >
          <div
            class="flex mt-3 rounded-full items-center overflow-hidden shadow-md bg-white"
          >
            <input
              class="text-gray-500 placeholder-gray-500 place w-full py-3 px-5 outline-none font-normal"
              type="text"
              name="document"
              placeholder="Utilize o CPF para buscar um talento..."
              v-model="$v.document.$model"
              v-mask="'###.###.###-##'"
            />
            <button
              type="submit"
              class="text-gray-500 cursor-pointer py-3 px-3 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
              :disabled="submitting"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
          <div v-if="$v.document.$error">
            <div class="error" v-if="!$v.document.required">
              Campo obrigatório.
            </div>
          </div>
        </form>
      </div>
    </div>
    <loader-animation v-if="isLoading" />
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import { required } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mask } from 'vue-the-mask';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'Search',

  title() {
    return `${process.env.VUE_APP_NAME} | Buscar Talento`;
  },

  directives: { mask },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      submitting: false,
      isLoading: false,
      document: ''
    };
  },

  validations: {
    document: {
      required
    }
  },

  methods: {
    submit() {
      this.submitting = true;
      this.isLoading = true;
      if (this.$v.$invalid) {
        this.$v.document.$touch();
        this.isLoading = false;
        this.submitting = true;
      } else {
        axios
          .post('/api/participantes/buscar', {
            document: this.document
          })
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'edit-participant',
              params: {
                id: data.data.data.id
              }
            });
          })
          .catch(({ response }) => {
            this.$toast.error(response.data.data.errors);
            this.isLoading = false;
          });
      }
    },

    goBack() {}
  }
};
</script>

<style scoped></style>
